export default {
  components: {

  },
  data: function () {
    return {
      role: "",
      item: {
        lot: null,
        brand: null,
        invoice_no: null,
        invoice_date: null,
        coil_no: null,
        size: null,
        color: null,
        net_weight: null,
        gross_weight: null,
        length: null,
        if_other_brand: null,
        warehouse: null,
        row: null,
        status: null,
        can_pick: true,
        price: null,
      },
    }
  },
  computed: {

  },
  mounted() {
    this.getRole()
    this.onInitData()
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
    },
    async onInitData() {
      this.busy = true
      const result = await this.$axios.get(
        this.$host + "/api/v1/china-order/" + this.$route.params.id,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      )
      this.item = result.data
      this.busy = false
    },
  }
}